import React from "react";
import ReactDOM from "react-dom";

import { AppContextProvider } from "./context/app-context";
import { InfoContextProvider } from "./context/info-context";
import { AuthContextProvider } from "./context/auth-context";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import reportWebVitals from "./reportWebVitals";

import App from "./App/App";

const theme = createTheme();
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <InfoContextProvider>
        <AuthContextProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <App />
                <ReactQueryDevtools />
              </QueryClientProvider>
            </ThemeProvider>
          </BrowserRouter>
        </AuthContextProvider>
      </InfoContextProvider>
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
