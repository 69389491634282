import { useRef, useMemo, useCallback } from "react";

const useTour = () => {
  const DUMP_DATA = useRef([
    {
      id: 1234,
      isActive: true,
      date: new Date(),
      entries: [
        {
          id: 123,
          type: "start",
          milage: 200100,
          time: "10:17:12",
          date: new Date(),
          position: "Mespelbrunn",
          description: "",
        },
        {
          id: 124,
          type: "break",
          milage: 200120,
          time: "10:30:12",
          date: new Date(),
          position: "Haibach",
          description: "Erster Patient",
        },
        {
          id: 125,
          type: "break",
          milage: 200120,
          time: "11:00:12",
          date: new Date(),
          position: "Haibach",
          description: "Weiterfahrt",
        },
        {
          id: 126,
          type: "break",
          milage: 200140,
          time: "11:20:12",
          date: new Date(),
          position: "Schweinheim",
          description: "Zweiter Patient",
        },
      ],
    },
    {
      id: 1235,
      date: new Date(),
      entries: [
        {
          id: 221,
          type: "start",
          milage: 200100,
          time: "10:17:12",
          date: new Date(),
          position: "Mespelbrunn",
          description: "",
        },
        {
          id: 222,
          type: "break",
          milage: 200120,
          time: "10:30:12",
          date: new Date(),
          position: "Nilkheim",
          description: "Erster Patient",
        },
        {
          id: 223,
          type: "break",
          milage: 200120,
          time: "11:00:12",
          date: new Date(),
          position: "Nilkheim",
          description: "Weiterfahrt",
        },
        {
          id: 224,
          type: "stop",
          milage: 200140,
          time: "11:20:12",
          date: new Date(),
          position: "Mespelbrunn",
          description: "Zuhause",
        },
      ],
    },
    {
      id: 1236,
      date: new Date(),
      entries: [
        {
          id: 323,
          type: "start",
          milage: 200100,
          time: "10:17:12",
          date: new Date(),
          position: "Mespelbrunn",
          description: "",
        },
        {
          id: 324,
          type: "break",
          milage: 200120,
          time: "10:30:12",
          date: new Date(),
          position: "Altenbuch",
          description: "Erster Patient",
        },
        {
          id: 325,
          type: "break",
          milage: 200120,
          time: "11:00:12",
          date: new Date(),
          position: "Altenbuch",
          description: "Weiterfahrt",
        },
        {
          id: 326,
          type: "stop",
          milage: 200140,
          time: "11:20:12",
          date: new Date(),
          position: "Mespelbrunn",
          description: "Zuhause",
        },
      ],
    },
  ]);

  const fetchPositions = useMemo(() => {
    const positions = [];
    DUMP_DATA.current.forEach((data) =>
      data.entries.forEach((entry) => {
        if (entry.position && !positions.includes(entry.position)) {
          positions.push(entry.position);
        }
      })
    );
    return positions;
  }, [DUMP_DATA]);

  const getTour = useCallback(
    (tourId) => {
      return DUMP_DATA.current.find((data) => data.id === tourId) || {};
    },
    [DUMP_DATA]
  );

  const getList = useMemo(() => {
    return DUMP_DATA.current;
  }, [DUMP_DATA]);

  return { getList, getTour, fetchPositions };
};

export default useTour;
