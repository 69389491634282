import { useCallback, useEffect, useRef } from "react";
import axios from "axios";
import useAppContext from "../context/app-context";

const useHttpClient = () => {
  const { setIsLoading } = useAppContext();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (apiPath, method = "GET", data = null, additionalHeaders = {}) => {
      setIsLoading(true);

      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      const url = [backendUrl, apiPath].join("/");
      try {
        return await axios({
          method,
          url,
          data,
          signal: httpAbortCtrl.signal,
        }).then(function (response) {
          activeHttpRequests.current = activeHttpRequests.current.filter(
            (abortCtrl) => abortCtrl !== httpAbortCtrl
          );
          setIsLoading(false);
          return response.data;
        });
      } catch (err) {
        setIsLoading(false);
        throw err;
      }
    },
    [backendUrl, setIsLoading]
  );

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return { sendRequest };
};

export default useHttpClient;
