import { useState, useCallback } from "react";
import {
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import useAuth from "../hooks/auth-hook";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { loginHandler } = useAuth();

  const handleSubmit = useCallback(
    async (email, password) => await loginHandler(email, password),
    [loginHandler]
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (event.code === "Enter") {
        handleSubmit(email, password);
      }
    },
    [email, password, handleSubmit]
  );

  return (
    <Container maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Bitte anmelden
        </Typography>
        <Box component='form' noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Adresse'
            name='email'
            autoComplete='email'
            autoFocus
            onChange={(event) => setEmail(event.target.value)}
            onKeyPress={handleKeyPress}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Passwort'
            type='password'
            id='password'
            autoComplete='current-password'
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={handleKeyPress}
          />
          <FormControlLabel
            control={<Checkbox value='remember' color='primary' />}
            label='Eingeloggt bleiben.'
          />
          <Button
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleSubmit(email, password)}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
