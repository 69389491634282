import { useCallback } from "react";
import { PropTypes } from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import StopIcon from "@mui/icons-material/FlagOutlined";
import BreakIcon from "@mui/icons-material/FmdGoodOutlined";
import StartIcon from "@mui/icons-material/PlayCircleOutlined";

const TourTable = (props) => {
  const { tourEntries } = props;

  const renderLabelIcon = useCallback((type) => {
    switch (type) {
      case "start":
        return <StartIcon fontSize='small' />;
      case "break":
        return <BreakIcon fontSize='small' />;
      case "stop":
        return <StopIcon fontSize='small' />;
      default:
        return null;
    }
  }, []);

  const renderEntityItem = useCallback(
    (entity) => {
      return (
        <TableRow key={entity.id}>
          <TableCell>{renderLabelIcon(entity.type)}</TableCell>
          <TableCell>{entity.position}</TableCell>
          <TableCell>{entity.time.substring(0, 5)}</TableCell>
          <TableCell>{entity.milage}</TableCell>
        </TableRow>
      );
    },
    [renderLabelIcon]
  );

  if (tourEntries.length > 0) {
    return (
      <TableContainer>
        <Table size='small'>
          <TableBody>
            {tourEntries.map((entity) => renderEntityItem(entity))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return null;
  }
};

TourTable.propTypes = {
  tourEntries: PropTypes.array.isRequired,
};

export default TourTable;
