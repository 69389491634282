import { useState, useCallback } from "react";
import { PropTypes } from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  Button,
  Divider,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import { DatePicker, TimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { de } from "date-fns/locale";

import GpsIcon from "@mui/icons-material/GpsFixedOutlined";
import HomeIcon from "@mui/icons-material/HomeOutlined";

import useTour from "../../hooks/tour-hook";

const TourEntryForm = (props) => {
  const { entityId, open, handleClose } = props;

  const [time, setTime] = useState(new Date());

  const { fetchPositions } = useTour();

  const dialogTitle = entityId ? "Eintrag ändern" : "Neuer Eintrag";

  const handleSubmit = useCallback(() => {
    console.log("Submit");
    handleClose();
  }, [handleClose]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={de}>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Grid
            container
            rowSpacing={{ xs: 2, md: 3 }}
            columnSpacing={1}
            alignItems='center'
          >
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6} sm={4} sx={{ textAlign: "center" }}>
              <DatePicker
                label='Datum'
                value={time}
                onChange={(newTime) => {
                  setTime(newTime);
                }}
                fullWidth
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={6} sm={4} sx={{ textAlign: "center" }}>
              <TimePicker
                label='Zeitpunkt'
                value={time}
                onChange={(newTime) => {
                  setTime(newTime);
                }}
                fullWidth
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
              <TextField
                label='km-Stand'
                fullWidth
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />
            </Grid>
            <Grid item xs={8} sm={10}>
              <Autocomplete
                freeSolo
                fullWidth
                options={fetchPositions.map((option) => option)}
                renderInput={(params) => (
                  <TextField {...params} label='Standort' />
                )}
              />
            </Grid>
            <Grid item xs={2} sm={1}>
              <Box display='flex' justifyContent='flex-end'>
                <IconButton disabled>
                  <HomeIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={2} sm={1}>
              <Box display='flex' justifyContent='flex-end'>
                <IconButton disabled>
                  <GpsIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleSubmit}>Speichern</Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

TourEntryForm.propTypes = {
  entityId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default TourEntryForm;
