import { useCallback, useEffect } from "react";

import useAuthContext from "../context/auth-context";
import useInfoContext from "../context/info-context";
import useHttpClient from "./http-hook";

const expirationTimeInHours = 1;

const useAuth = () => {
  const { setToken, setUserId } = useAuthContext();
  const { setInfo } = useInfoContext();

  const { sendRequest } = useHttpClient();

  const login = useCallback(
    (userId, token) => {
      setToken(token);
      setUserId(userId);

      const tokenExpirationDate = new Date(
        new Date().getTime() + 1000 * 60 * 60 * expirationTimeInHours
      );
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: userId,
          token: token,
          expirationDate: tokenExpirationDate.toISOString(),
        })
      );
    },
    [setToken, setUserId]
  );

  const loginHandler = useCallback(
    async (email, password) => {
      try {
        const data = await sendRequest("users/login", "POST", {
          email: email,
          password: password,
        });
        login(data.userId, data.token);
      } catch (err) {
        setInfo("Fehler beim Login", "error");
      }
    },
    [sendRequest, login, setInfo]
  );

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    localStorage.clear("userData");
  }, [setToken, setUserId]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (
      userData &&
      userData.token &&
      new Date(userData.expirationDate) > new Date()
    ) {
      login(userData.userId, userData.token);
    }
  }, [login]);

  return { loginHandler, logout };
};

export default useAuth;
