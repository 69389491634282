import { useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";
import { styled } from "@mui/material/styles";
import { Container, Typography, Button } from "@mui/material";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionActions from "@mui/material/AccordionActions";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";

import TourTable from "../TourTable/TourTable";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const TourAccordion = (props) => {
  const { tourItem } = props;

  const history = useHistory();

  const styleSummary = tourItem.isActive
    ? { backgroundColor: "secondary.main" }
    : {};

  const renderSummary = useMemo(() => {
    const weekDays = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
    const day = weekDays[tourItem.date.getDay()];
    const dd = String(tourItem.date.getDate()).padStart(2, "0");
    const mm = String(tourItem.date.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yy = String(tourItem.date.getFullYear()).substring(2, 4);
    const tourDateString = day + ", " + dd + "." + mm + "." + yy;

    const styleTypo = { fontSize: ["small", "medium"], alignSelf: "center" };
    const styleIcon = { fontSize: ["medium", "large"] };

    return (
      <>
        <Typography variant='h6' sx={{ flexGrow: 1 }}>
          {tourDateString}
        </Typography>
        <Typography sx={{ ...styleTypo }}>
          <TimerOutlinedIcon sx={{ ...styleIcon }} /> 5,5 h
        </Typography>
        <Typography sx={{ ...styleTypo, ml: 2 }}>
          <DirectionsCarOutlinedIcon sx={{ ...styleIcon }} /> 100 km
        </Typography>
      </>
    );
  }, [tourItem]);

  const tourEditHandler = useCallback(
    (tourId) => history.push("/tour/" + tourId),
    [history]
  );

  return (
    <Accordion expanded={tourItem.isActive}>
      <AccordionSummary sx={{ ...styleSummary }}>
        {renderSummary}
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Container sx={{ m: 0, p: [0] }}>
          <TourTable tourEntries={tourItem.entries} />
        </Container>
      </AccordionDetails>
      <AccordionActions>
        <Button onClick={() => tourEditHandler(tourItem.id)}>Bearbeiten</Button>
      </AccordionActions>
    </Accordion>
  );
};

TourAccordion.propTypes = {
  tourItem: PropTypes.object.isRequired,
};

export default TourAccordion;
