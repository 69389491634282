import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import useAuth from "../../hooks/auth-hook";

const Header = () => {
  const { logout } = useAuth();

  return (
    <AppBar>
      <Toolbar>
        <Typography variant='h6' sx={{ flexGrow: 1 }}>
          Simple-Tour-Tracker
        </Typography>
        <IconButton onClick={logout}>
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
