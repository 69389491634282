import { Box, Modal, CircularProgress } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: 0,
};

const Spinner = () => (
  <Modal
    open
    aria-labelledby='modal-modal-title'
    aria-describedby='modal-modal-description'
  >
    <Box sx={style}>
      <CircularProgress />
    </Box>
  </Modal>
);

export default Spinner;
