import { CssBaseline, Container } from "@mui/material";

import useAppContext from "../context/app-context";
import useAuthContext from "../context/auth-context";

import Router from "./Router";

import Spinner from "../components/Feedback/Spinner";
import InfoToast from "../components/Feedback/InfoToast";
import Header from "./../components/Header/Header";
import Footer from "../components/Footer/Footer";

const App = () => {
  const { isLoading } = useAppContext();
  const { token } = useAuthContext();

  return (
    <div>
      <CssBaseline />
      {isLoading && <Spinner />}
      <Container
        maxWidth='xl'
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "100vh",
        }}
      >
        {!!token && <Header />}
        <Container
          component='main'
          maxWidth='md'
          sx={{ flexGrow: 1, mt: 8, px: [0] }}
        >
          <Router />
        </Container>
        <Footer />
      </Container>
      <InfoToast />
    </div>
  );
};

export default App;
