import { useEffect, useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography, Container, Button, Divider } from "@mui/material";

import useTour from "../hooks/tour-hook";

import TourTable from "../components/TourTable/TourTable";
import TourEntryForm from "../components/TourEntryForm/TourEntryForm";
import { getDateAsShortString } from "../utils/dateParser";

const Tour = () => {
  let { tourId } = useParams();

  const history = useHistory();

  const [tour, setTour] = useState({ date: new Date(), entries: [] });
  const [formOpen, setFormOpen] = useState(false);

  const { getTour } = useTour();

  useEffect(() => {
    const tourIdInt = parseInt(tourId);
    if (tourIdInt && tour.id !== tourIdInt) {
      const tourData = getTour(tourIdInt);
      if (tourData) {
        setTour(tourData);
      }
    }
  }, [getTour, tour.id, tourId]);

  const backToListHandler = useCallback(() => {
    history.push("/list");
  }, [history]);

  return (
    <>
      <Container
        sx={{
          p: [0],
          mt: [2, 3, 4],
          mb: [1],
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant='h5'>Tour</Typography>
        <Button onClick={backToListHandler}>zur Liste</Button>
      </Container>
      <Typography variant='h6'>
        {getDateAsShortString(tour.date)} - {tourId ? "bearbeiten" : "NEU"}
      </Typography>
      <TourEntryForm open={formOpen} handleClose={() => setFormOpen(false)} />
      <Container sx={{ p: [0], my: [3] }}>
        <Divider />
        <TourTable tourEntries={tour.entries} />
      </Container>
      <Container sx={{ mt: [2], display: "flex", justifyContent: "center" }}>
        <Button variant='contained' onClick={() => setFormOpen(true)}>
          Neuer Eintrag
        </Button>
      </Container>
    </>
  );
};

export default Tour;
