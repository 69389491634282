import { useContext, useState, createContext } from "react";

export const InfoContext = createContext({
  infoMessage: null,
  infoType: "success",
  setInfo: () => {},
  handleInfoClose: () => {},
});

export const InfoContextProvider = (props) => {
  const [infoMessage, setInfoMessage] = useState();
  const [infoType, setInfoType] = useState("success");

  const handleInfoClose = () => {
    setInfoMessage(null);
  };

  const setInfo = (infoMessage, infoType = "success") => {
    setInfoMessage(infoMessage);
    setInfoType(infoType);
  };

  return (
    <InfoContext.Provider
      value={{
        infoMessage,
        infoType,
        setInfo,
        handleInfoClose,
      }}
    >
      {props.children}
    </InfoContext.Provider>
  );
};

const useInfoContext = () => {
  const { infoMessage, infoType, setInfo, handleInfoClose } =
    useContext(InfoContext);
  return {
    infoMessage,
    infoType,
    setInfo,
    handleInfoClose,
  };
};

export default useInfoContext;
