import { Container, Typography, Link } from "@mui/material";

import useAppContext from "../../context/app-context";

const Footer = () => {
  const { baseUrl } = useAppContext();

  return (
    <Container component='footer' sx={{ mb: 2, mt: 2 }}>
      <Typography variant='body2' color='text.secondary' align='center'>
        {"Copyright © "}
        <Link color='inherit' href={baseUrl}>
          Simple Tour Tracker
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Container>
  );
};

export default Footer;
