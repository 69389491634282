import { useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, Typography } from "@mui/material";

import useTour from "../hooks/tour-hook";

import TourAccordion from "../components/TourAccordion/TourAccordion";

const TourList = () => {
  const history = useHistory();

  const { getList } = useTour();

  const renderTourList = useMemo(() => {
    return (
      <>
        {getList.map((tourItem) => (
          <TourAccordion key={tourItem.id} tourItem={tourItem} />
        ))}
      </>
    );
  }, [getList]);

  const newTourHandler = useCallback(() => {
    history.push("/tour");
  }, [history]);

  return (
    <>
      <Container
        sx={{
          p: [0],
          mt: [2, 3, 4],
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant='h5'>Tour-Liste</Typography>
        <Button variant='contained' onClick={newTourHandler}>
          Neue Tour
        </Button>
      </Container>
      <Container sx={{ p: [0], mt: [2, 3, 4] }}>{renderTourList}</Container>
    </>
  );
};

export default TourList;
