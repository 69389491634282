import { useContext, useState, createContext } from "react";

const AuthContext = createContext({
  userId: null,
  setUserId: () => {},
  token: null,
  setToken: () => {},
});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);

  return (
    <AuthContext.Provider value={{ userId, setUserId, token, setToken }}>
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const { userId, setUserId, token, setToken } = useContext(AuthContext);
  return { userId, setUserId, token, setToken };
};

export default useAuthContext;
