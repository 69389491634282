import { Switch, Route, Redirect } from "react-router-dom";

import useAuthContext from "../context/auth-context";

import Login from "../pages/Login";
import TourList from "../pages/TourList";
import Tour from "../pages/Tour";

const Router = () => {
  const { token } = useAuthContext();

  console.log(token);

  if (!!token) {
    return (
      <Switch>
        <Route path='/list' component={TourList} />
        <Route path='/tour/:tourId' component={Tour} />
        <Route path='/tour/' component={Tour} />
        <Redirect to='/list' />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path='/login' component={Login} />
        <Redirect to='/login' />
      </Switch>
    );
  }
};

export default Router;
