import { Snackbar, Alert } from "@mui/material";
import useInfoContext from "../../context/info-context";

const InfoToast = () => {
  const { infoMessage, infoType, handleInfoClose } = useInfoContext();

  return (
    <Snackbar
      open={!!infoMessage}
      autoHideDuration={6000}
      onClose={handleInfoClose}
    >
      <Alert
        onClose={handleInfoClose}
        severity={infoType}
        sx={{ width: "100%" }}
      >
        {infoMessage}
      </Alert>
    </Snackbar>
  );
};

export default InfoToast;
