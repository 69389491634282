import { useContext, useState, createContext } from "react";

const AppContext = createContext({
  baseUrl: "http://localhost:3000",
  isLoading: false,
  setIsLoading: () => {},
});

export const AppContextProvider = (props) => {
  const baseUrl = process.env.REACT_APP_FRONTEND_URL;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <AppContext.Provider value={{ baseUrl, isLoading, setIsLoading }}>
      {props.children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const { baseUrl, isLoading, setIsLoading } = useContext(AppContext);
  return { baseUrl, isLoading, setIsLoading };
};

export default useAppContext;
